input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-pink {
  color: #fff !important;
  background-color: #ec4899 !important;
}
.btn-pink:hover {
  background-color: #db2777 !important;
}

.form-label-wrapper {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}
.info-tooltip {
  font-size: 18px;
  color: #7647e9 !important;
}

.info-tooltip:hover {
  color: #3b0764 !important;
}

.tooltip-inner {
  max-width: 18rem;
  background-color: #f3e8ff !important;
  color: #7647e9 !important;
}

.expired-container {
  max-width: 48rem;
  width: 100%;
  margin: 2.2rem auto;
}
.expired-card {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  box-shadow: rgba(60, 72, 88, 0.15) 0px 0px 3px;
}

.expired-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7647e9;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 1.5rem 0;
}

.expired-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

.expired-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2.6rem;
  padding-block: 0.75rem;
}

.expired-contact-icon {
  color: #646464;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.expired-card-warning {
  max-width: 36rem;
  width: 100%;
  background-color: #ffe297;
  color: #646464;
  border-radius: 0.5rem;
  text-align: center;
  padding: 8px;
  margin-top: 1.5rem;
}

.expired-contact-icon {
  width: 18px;
  height: 18px;
  margin-right: 0.75rem;
}

.text-primary-expired {
  color: #7647e8;
  font-size: 14px;
}

.expired-social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.8rem;
}

.expired-text {
  color: #8492a6;
  text-align: center;
}

.expired-social-wrapper {
  display: flex;
  column-gap: 1.5rem;
  margin-bottom: 0.75rem;
}

.expired-social-link > img {
  width: 16px;
  height: 16px;
}
.expired-legal {
  color: #8492a6;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
}

.expired-register {
  border: none;
  background-color: #7923f7;
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  padding: 0.7rem 2.4rem;
  margin-bottom: 3.6rem;
}

.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}

.lift:focus,
.lift:hover {
  box-shadow: 0 1rem 2.5rem rgba(18, 38, 63, 0.1),
    0 0.5rem 1rem -0.75rem rgba(18, 38, 63, 0.1) !important;
  transform: translate3d(0, -3px, 0);
}

.feature-price {
  display: flex;
  align-items: end;
  column-gap: 0.25rem;
}

.feature-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.feature-check-icon {
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.feature-check-icon-standart {
  font-size: 0.75rem;
  margin-right: 0.5rem;
  color: #fff;
}

.comparison-table {
  position: relative;
  width: 100%;
  display: block;
  overflow-x: auto;
  padding: 1.5rem;
}

.comparisin-table div {
  white-space: nowrap;
}

.comparison-row-container {
  display: grid;
  grid-template-columns: repeat(14, minmax(80px, 1fr));
  padding: 1rem 0;
}

.comparison-row {
  border-bottom: 1px solid #eee;
  padding: 1rem 0;
}

.row-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 2 / span 2;
}
.row-cell-text {
  font-size: 14px;
  color: #6e6e6e;
}

.row-feature-cell-border {
  border-top: 2px solid #eee;
  padding-top: 2rem;
}

.row-feature-cell-border-standart {
  border-color: #7647e8 !important;
}

.row-feature-cell {
  display: flex;
  align-items: center;
  justify-content: start;
  grid-column: span 3 / span 3;
}

.comparison-tier-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.comparison-tier-title {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.22px;
  font-weight: 600;
}

.comparison-tier-price {
  font-size: 14px;
  color: #6e6e6e;
  margin-bottom: 0;
}

.comparison-tier-price > p {
  letter-spacing: -0.02em;
  color: #000;
  font-weight: 600;
}

.comparison-section-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.comparison-feature-title {
  color: #666;
  font-size: 15px;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.comparison-tier-button:hover {
  opacity: 0.8;
}

.price-tabs {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 20px 0 rgba(39, 39, 39, 0.1);
  box-shadow: 0 5px 20px 0 rgba(39, 39, 39, 0.1);
  display: inline-block;
  padding: 0.25rem;
  border-radius: 9999px;
  border: 1px solid #7923f7;
  margin-bottom: 2rem;
  z-index: 10;
}

.btn-primary-outline {
  border: 1px solid #7923f7 !important;
  color: #7923f7 !important;
  background-color: transparent !important;
}

.btn-primary-outline:hover {
  color: #fff !important;
  background-color: #7923f7 !important;
}

.text-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.blog-tabs {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.blog-tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  cursor: pointer;
  color: #999 !important;
  border-bottom: 1px solid #eee;
}

.blog-tab-item:hover {
  color: #7647e8 !important;
}

.blog-tab-item.active {
  color: #7647e8 !important;
  border-bottom: 2px solid #7647e8;
}

.payment-method-item {
  display: flex;
  border: 1px solid #eee;
  border-radius: 0.375rem;
  cursor: pointer;
  padding: 1rem 1.5rem !important;
  margin-bottom: 1rem;
  cursor: pointer;
}

.payment-method-item:hover {
  border-color: #7647e8;
  color: #7647e8;
}

.payment-method-item-border {
  border-color: #7647e8;
  color: #7647e8;
}

.payment-method-item label {
  width: 100%;
  flex: 0 0 100%;
}
.payment-method-item > label .payment-title {
  font-weight: 600;
  font-size: 1rem;
}
.payment-method-item > label .payment-text {
  font-size: 0.7rem;
  color: #666;
}

.deeplink-wrapper {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
}

.deeplink-img {
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1/1;
  border-radius: 0.375rem;
  object-fit: cover;
  object-position: center;
}

.payment-success-emoji {
  background-color: #2eca8b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #2eca8b;
  width: 6rem;
  height: 6rem;
}

.payment-success-emoji > img {
  width: 3.25rem;
  height: 3.25rem;
}

@media (min-width: 1024px) {
  .text-primary-expired {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .price-tabs {
    margin-bottom: 60px;
  }
}

.price-tabs .nav-link {
  color: #7923f7;
  font-weight: 500;
  font-size: 16px;
  padding: 0.5rem 0.5rem;
  display: inline-block;
  text-transform: capitalize;
  border-radius: 9999px;
}

.price-tabs-pill {
  position: absolute;
  background-color: #7923f7;
  width: 50%;
  inset-block: 0.2rem;
  left: 0.2rem;
  border-radius: 9999px;
  z-index: 10;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: -10;
}

.price-tabs-pill.active {
  transform: translateX(4.8rem);
}

.price-tabs .nav-link.active {
  /* background-color: #7923f7; */
  color: #fff;
}

.mr-icon {
  margin-right: 0.25rem;
}

.pricing-plan-card-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 668px) {
  .pricing-plan-card-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .price-tabs .nav-link {
    padding: 12px 40px;
  }
  .price-tabs-pill.active {
    transform: translateX(8.8rem);
  }
}

@media (min-width: 1024px) {
  .pricing-plan-card-container {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
